import request from './request'

const AVAILABILITY_TYPES = {
  DAYTIME: 'daytime',
  EVENING: 'evening',
  DEFAULT: 'default'
}

export default {
  data () {
    return {
      AVAILABILITY_TYPES
    }
  },
  computed: {

  },
  mixins: [request],
  methods: {
    createDriverAvailability (data) {
      //    {
      //     "endAt": "2022-12-18 18:00:00", // req
      //     "startAt": "2022-01-17 04:00:00",// req
      //     "available": true,// req
      //     "type": "daytime",// req
      //     "day": "2022-12-18",// req
      //     "driver": "17"// req,
      //     "brand": 1 // REQUIRED ONLY FOR ADMIN
      // }

      const body = {
        data
      }

      return this.request('POST', '/driver-availability', body)
    },
    updateDriverAvailability (id, data) {
      //  {
      //       "endAt": "2022-12-18 18:00:00", // optional
      //       "startAt": "2022-01-17 04:00:00",// optional
      //       "available": true,// optional
      //   }

      const body = {
        data
      }

      return this.request('PATCH', `/driver-availability/${id}`, body)
    },
    deleteDriverAvailability (id) {
      return this.request('DELETE', `/driver-availability/${id}`)
    },
    getDriverAvailability (params) {
      //   Filters:
      // - driver: ID // required
      // - brand: ID // required for admin, optional for driver
      // - day: 2020-01-01 // optional
      // - startAtFrom, startAtTo, endAtFrom, endAtTo, createdAtFrom, createdAtTo, modifiedAtFrom, modifiedAtTo are optional

      // Sorts: id, day (default DESC), createdAt, brand, startAt, endAt

      const body = {
        params
      }
      return this.request('GET', '/driver-availability', body)
    },
    createDefaultSchedule (data) {
      // {
      //     "driver": "17", // req
      //     "monday": false, // req
      //     "friday": true, // req
      //     "tuesday": false, // req
      //     "sunday": true, // req
      //     "thursday": true, // req
      //     "wednesday": false, // req
      //     "type": "evening", // req
      //     "saturday": true // req
      // }

      const body = {
        data
      }

      return this.request('POST', '/driver-default-schedule', body)
    },
    updateDefaultSchedule (id, data) {
      // {
      //     "monday": false, // optional
      //     "friday": true, // optional
      //     "tuesday": false, // optional
      //     "sunday": true, // optional
      //     "thursday": true, // optional
      //     "wednesday": false, // optional
      //     "type": "evening", // optional
      //     "saturday": true // optional
      // }

      const body = {
        data
      }

      return this.request('PATCH', `/driver-default-schedule/${id}`, body)
    },
    deleteDefaultSchedule (id) {
      return this.request('DELETE', `/driver-default-schedule/${id}`)
    },
    getDefaultSchedules (params) {
      // - driver: ID // required
      //   - brand: ID // required for admin, optional for driver
      //   - createdAtFrom, createdAtTo, modifiedAtFrom, modifiedAtTo are optional

      // Sorts: id (default ESC), createdAt, brandts: id, day (default DESC), createdAt, brand, startAt, endAt

      const body = {
        params
      }

      return this.request('GET', '/driver-default-schedule', body)
    },
    getDefaultSchedule (id) {
      return this.request('GET', `/driver-default-schedule/${id}`)
    }
  }
}
