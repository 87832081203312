<template>
  <div class="icon-conatiner">
    <v-icon
      :color="activeStatusColor"
      large
    >
      save
    </v-icon>
  </div>
</template>

<script>
import { SAVING_STATUS } from 'Constants/common'

const statusColorList = {
  [SAVING_STATUS.default]: 'grey lighten-2',
  [SAVING_STATUS.wait]: 'yellow',
  [SAVING_STATUS.saved]: 'green',
  [SAVING_STATUS.error]: 'red'
}

export default {
  props: {
    status: {
      type: String,
      default: SAVING_STATUS.default
    }
  },
  data () {
    return {
      activeStatusColor: statusColorList[this.status]
    }
  },
  watch: {
    status: {
      handler () {
        this.activeStatusColor = statusColorList[this.status]
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
    .icon-conatiner {
        position: fixed;
        top: 9px;
        right: 25px;
        z-index: 23123123;
    }
</style>
