<template>
  <div/>
</template>

<script>
const RIDE_STATUS = {
  draft: { id: 10, alias: 'draft', sequence: 8 },
  retracted: { id: 11, alias: 'retracted', sequence: 9 },
  new: { id: 1, alias: 'new', sequence: 10 },
  failed: { id: 16, alias: 'failed', sequence: 15 },
  subscribed: { id: 2, alias: 'subscribed', sequence: 20 },
  elected: { id: 3, alias: 'elected', sequence: 30 },
  underway: { id: 12, alias: 'underway', sequence: 34 },
  waiting: { id: 13, alias: 'waiting', sequence: 37 },
  started: { id: 4, alias: 'started', sequence: 40 },
  break: { id: 14, alias: 'break', sequence: 45 },
  ended: { id: 5, alias: 'ended', sequence: 50 },
  declared: { id: 6, alias: 'declared', sequence: 60 },
  declaration_approved: { id: 17, alias: 'declaration_approved', sequence: 65 },
  paid: { id: 8, alias: 'paid', sequence: 80 },
  passenger_invoiced: { id: 18, alias: 'passenger_invoiced', sequence: 84 },
  driver_invoiced: { id: 15, alias: 'driver_invoiced', sequence: 85 },
  invoiced: { id: 7, alias: 'invoiced', sequence: 88 },
  paid_out: { id: 9, alias: 'paid_out', sequence: 90 }
}

export default {
  computed: {
    statusList () {
      return this.statuses()
    },
    statusListFilters () {
      return this.statusesFilters()
    },
    statusListObjects () {
      return Object.keys(this.statusListFilters).map(key => { return { name: this.statusListFilters[key], key: key } })
    },
    rideStatus () {
      return RIDE_STATUS
    }
  },
  methods: {
    statuses () {
      return {
        [RIDE_STATUS.draft.alias]: 'concept',
        [RIDE_STATUS.new.alias]: 'nieuw',
        [RIDE_STATUS.subscribed.alias]: 'kies chauffeur',
        [RIDE_STATUS.elected.alias]: 'rit ingepland',
        [RIDE_STATUS.started.alias]: 'rit gestart',
        [RIDE_STATUS.underway.alias]: 'Onderweg',
        [RIDE_STATUS.waiting.alias]: 'Wacht op klant',
        [RIDE_STATUS.ended.alias]: 'rit beëindigd',
        [RIDE_STATUS.declared.alias]: 'gedeclareerd',
        [RIDE_STATUS.declaration_approved.alias]: 'verklaring goedgekeurd',
        [RIDE_STATUS.invoiced.alias]: 'gefactureerd',
        [RIDE_STATUS.paid.alias]: 'betaald',
        [RIDE_STATUS.paid_out.alias]: 'betaald',
        [RIDE_STATUS.break.alias]: 'Pauzeren',
        [RIDE_STATUS.passenger_invoiced.alias]: 'passenger_invoiced temp',
        [RIDE_STATUS.driver_invoiced.alias]: 'chauffeur gefactureerd',
        [RIDE_STATUS.failed.alias]: 'gefaald'
      }
    },

    statusesFilters () {
      return {
        draft: 'Concept',
        new: 'Nieuw',
        subscribed: 'Kies chauffeur',
        elected: 'Rit ingepland',
        started: 'Rit gestart',
        ended: 'Rit beëindigd',
        declared: 'Gedeclareerd',
        invoiced: 'Gefactureerd',
        paid: 'Betaald',
        paid_out: 'Uitbetaald'
      }
    }
  }
}
</script>
