<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex
          xs12
          class="pa-0">
          <app-card
            :footer="true"
            :heading="`Driver: ${userData.name || ''}`"
            col-classes="xl12 lg12 md12 sm12 xs12 pa-0"
            custom-classes="mb-0"
          >
            <v-tabs grow>
              <v-tab>User information</v-tab>
              <v-tab>Driver information</v-tab>
              <v-tab @change="loadNotes">Notes</v-tab>
              <v-tab>Driver schedule</v-tab>
              <v-tab>Declarations</v-tab>
              <v-tab>Files</v-tab>
              <v-tab-item>
                <v-form
                  v-if="user"
                  ref="userForm"
                  lazy-validation>
                  <v-container grid-list-xl>
                    <v-layout
                      row
                      wrap>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.firstName"
                          label="First name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.lastName"
                          label="Last name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.email"
                          label="Email"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-if="user.phone"
                          v-model="user.phone.number"
                          label="Phone"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.country"
                          label="Country"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm4>
                        <v-text-field
                          v-model="user.language"
                          label="Language"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6>
                        <v-select
                          v-if="brandList.length"
                          :items="brandList"
                          :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                          v-model="user.brands"
                          :item-text="(val) => { return val.name }"
                          clearable
                          item-value="id"
                          multiple
                          label="Brands"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12>
                        <v-text-field
                          :max-length="TEXT_FIELD_MAX_LENGTH"
                          v-model="user.plainPassword"
                          :append-icon="showPasswordField ? 'visibility' : 'visibility_off'"
                          :type="showPasswordField ? 'text' : 'password'"
                          label="Plain password"
                          @click:append="showPasswordField = !showPasswordField"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        id="login"
                        xs6>
                        <v-overflow-btn
                          :disabled="!user.email"
                          :items="loginOptions"
                          label="Select login option..."
                          segmented
                          class="login-btn"
                          target="#login"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-btn
                          color="primary"
                          @click="saveUser()"
                        >Save</v-btn>
                        <v-btn
                          color="error"
                          @click="$router.push('/tables/drivers')"
                        >Close</v-btn>
                      </v-flex>
                    </v-layout>
                    <!-- <router-link
                      v-if="user"
                      :to="`/tables/users/${user.id}`" >
                      User details
                    </router-link> -->
                  </v-container>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <rotate-square2 v-if="loading" />
                  <v-container
                    v-else
                    grid-list-xl>
                    <v-layout
                      row
                      class="align-stretch"
                      wrap>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="user.firstName"
                          label="First name"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="user.lastName"
                          label="Last name"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="user.email"
                          label="Email"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-if="user.phone"
                          v-model="user.phone.number"
                          label="Phone"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="userData.companyName"
                          class="fs-14"
                          label="Company name"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <InputHotKeyWrapper>
                          <v-text-field
                            v-model="userData.kvkNumber"
                            class="fs-14"
                            type="number"
                            label="KvK number"
                            @blur="blurSaving"
                            @input="inputSaving"
                          />
                        </InputHotKeyWrapper>
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-select
                          v-if="brandList.length"
                          :items="brandList"
                          :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                          v-model="userData.brands"
                          :item-text="(val) => { return val.name }"
                          clearable
                          item-value="id"
                          multiple
                          label="Brands"
                          class="fs-14"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-textarea
                          v-model="userData.address"
                          label="Address"
                          rows="1"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="userData.ibanNumber"
                          label="Iban number"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="userData.vatNumber"
                          :disabled="!userData.isKOR && !userData.isZZP"
                          label="VAT number"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-autocomplete
                          v-model="userData.certificates"
                          :items="certificatesList"
                          :item-text="(val) => { return val.title + ' id: ' + val.id }"
                          :item-value="val => val"
                          multiple
                          label="Certificate"
                          class="fs-14"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-autocomplete
                          v-model="userData.regions"
                          :items="regionList"
                          :item-text="(val) => { return val.name + ' id: ' + val.id }"
                          :item-value="val => val"
                          multiple
                          name="Region"
                          label="Region"
                          class="fs-14"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-switch
                          v-model="user.active"
                          label="Active"
                          class="ml-3"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-switch
                          v-model="userData.hasTimeLimitsForFixedPrice"
                          label="Time limits for fixed price"
                          class="ml-3"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2
                      >
                        <v-switch
                          v-model="userData.business"
                          label="Business"
                          class="ml-3"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        v-if="roleList"
                        xs6
                        sm3
                        lg2>
                        <v-select
                          :items="Object.keys(roleList)"
                          :rules="[v => v.length>0 || 'Please select at least 1 user role']"
                          v-model="user.roles"
                          multiple
                          label="Roles"
                          class="fs-14"
                          @change="blurSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs6
                        sm3
                        lg2>
                        <v-text-field
                          v-model="userData.personnelNumber"
                          type="text"
                          label="Personnel number"
                          class="fs-14"
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        id="login"
                        xs12
                        sm6
                        lg4>
                        <v-overflow-btn
                          :disabled="!user.email"
                          :items="loginOptions"
                          label="Select login option..."
                          segmented
                          class="login-btn fs-14 mt-0"
                          target="#login"
                        />
                      </v-flex>
                    </v-layout>
                    <div class="d-flex justify-end mb-3">
                      <v-btn
                        style="flex: initial !important;"
                        color="primary"
                        class="fs-14 ma-0"
                        @click="sendWelcome()">Send welcome e-mail</v-btn>
                    </div>
                    <v-layout
                      row
                      class="align-stretch"
                      wrap>
                      <v-flex
                        v-if="userData.certificates && userData.certificates.length"
                        xs12
                      >
                        <v-data-table
                          :headers="[ { text: 'id', sortable: false }, { text: 'Title', sortable: false }, { text: 'Obtained At', sortable: false }, { text: '', sortable: false } ]"
                          :items="userData.certificates"

                          hide-actions >
                          <template
                            slot="items"
                            slot-scope="props">
                            <td>
                              {{ props.item.id }}
                            </td>
                            <td>
                              {{ props.item.title }}
                            </td>
                            <td>
                              <v-text-field
                                v-model="props.item.obtainedAt"
                                :rules="[v => Boolean(v) || 'Please provide a Obtained at']"
                                type="date"
                                label="Obtained at"
                                class="mb-4"
                                @blur="blurSaving"
                                @input="inputSaving"
                              />
                            </td>
                            <td>
                              <v-btn
                                :loading="deleteButtonLoader"
                                color="warning"
                                class="mr-3"
                                @click="deleteDriverCertificate(props.item.originId)"
                              >
                                Delete
                              </v-btn>
                            </td>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      row
                      class="align-stretch"
                      wrap>
                      <v-flex
                        xs12
                        md3>
                        <v-textarea
                          v-model="userData.description"
                          label="Description"
                          class="fs-14"
                          hide-details
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3>
                        <v-textarea
                          v-model="userData.adminNote"
                          label="Admin note"
                          class="fs-14"
                          hide-details
                          @blur="blurSaving"
                          @input="inputSaving"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md6>
                        <div class="d-flex align-end">
                          <div
                            v-if="userData.imageData != null && userData.imageData.length > 0"
                            class="image-preview column-layout justify-center align-center mr-3"
                            style="flex: initial !important;">
                            <img
                              :src="userData.imageData"
                              class="preview">
                          </div>
                          <div style="margin-left: auto;">
                            <input
                              id="file"
                              ref="fileInputAvatar"
                              type="file"
                              style="display: none"
                              multiple
                              accept="image/*"
                              @change="(e) => { onFilePicked(e); previewFiles(e); }">
                            <div>
                              <div class="d-flex mb-1">
                                <v-switch
                                  v-model="userData.isKOR"
                                  label="KOR"
                                  class="ma-0"
                                  hide-details
                                  @change="blurSaving"
                                />
                                <v-switch
                                  v-model="userData.isZZP"
                                  label="ZZP"
                                  class="ma-0"
                                  hide-details
                                  @change="blurSaving"
                                />
                              </div>
                              <div class="fs-12">Afbeeldingsgrootte: 124 x 124 px</div>
                            </div>
                            <v-btn
                              class="attach-button ma-0"
                              color="primary"
                              prepend-icon="attach_file"
                              @click="onPickFile"
                            >{{ avatarFile.name }}</v-btn>
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                    <div class="field-wrapper mt-5">
                      <v-layout
                        row
                        wrap>
                        <v-flex
                          xs6
                          sm3
                          lg2>
                          <v-text-field
                            v-model="userData.minHoursPerRide.time"
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            label="Min Hours Per Ride"
                            class="fs-14"
                            @blur="blurSaving"
                            @input="inputSaving"
                          />
                        </v-flex>
                        <v-flex
                          xs6
                          sm3
                          lg2>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="userData.hoursExperience"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              type="number"
                              label="Experience hours"
                              class="fs-14"
                              @blur="blurSaving"
                              @input="inputSaving"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex
                          xs6
                          sm3
                          lg2>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="userData.yearsExperience"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              type="number"
                              label="Experience years"
                              class="fs-14"
                              @blur="blurSaving"
                              @input="inputSaving"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        row
                        class="align-stretch"
                        wrap>
                        <v-flex
                          v-if="userData.tariffs"
                          xs6
                          sm3
                          lg2>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="userData.tariffs.driveme"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              label="DriveMe Tariff"
                              type="number"
                              class="fs-14"
                              @blur="blurSaving"
                              @input="inputSaving"
                            />
                          </InputHotKeyWrapper>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="userData.tariffs.edrivers"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              label="Edrivers Tariff"
                              type="number"
                              class="fs-14"
                              @blur="blurSaving"
                              @input="inputSaving"
                            />
                          </InputHotKeyWrapper>
                          <InputHotKeyWrapper>
                            <v-text-field
                              v-model="userData.tariffs.rentabob"
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              label="Rentabob Tariff"
                              type="number"
                              class="fs-14"
                              @blur="blurSaving"
                              @input="inputSaving"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex
                          xs6
                          sm3
                          lg2>
                          <v-autocomplete
                            :disabled="!brandList.length || !userData.brands || !userData.brands.length || !userData.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.DRIVE_ME).id)"
                            v-model="driverTypes.driveme"
                            :items="typesListBrand(BRAND.DRIVE_ME)"
                            :item-text="(val) => { return val.title + ' id: ' + val.id }"
                            label="DriveMe Type id"
                            item-value="id"
                            clearable
                            class="fs-14"
                            @change="blurSaving"
                          />
                          <v-autocomplete
                            :disabled="!brandList.length || !userData.brands || !userData.brands.length || !userData.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.EDRIVERS).id)"
                            v-model="driverTypes.edrivers"
                            :items="typesListBrand(BRAND.EDRIVERS)"
                            :item-text="(val) => { return val.title + ' id: ' + val.id }"
                            label="E-drivers Type id"
                            item-value="id"
                            clearable
                            class="fs-14"
                            @change="blurSaving"
                          />
                          <v-autocomplete
                            :disabled="!brandList.length || !userData.brands || !userData.brands.length || !userData.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.RENTABOB).id)"
                            v-model="driverTypes.rentabob"
                            :items="typesListBrand(BRAND.RENTABOB)"
                            :item-text="(val) => { return val.title + ' id: ' + val.id }"
                            label="Rent A Bob Type id"
                            item-value="id"
                            clearable
                            class="fs-14"
                            @change="blurSaving"
                          />
                        </v-flex>
                      </v-layout>
                    </div>
                    <router-link
                      :to="`/dashboard/ride-overview?driver=${$route.params.id}`"
                      @click.native="rideOverviewClick" >
                      Ride overview
                    </router-link>
                  </v-container>
                  <v-btn
                    color="primary"
                    class="mr-3 ml-4"
                    @click="saveFunction()"
                  >Save</v-btn>
                  <v-btn
                    color="error"
                    @click="$router.push('/tables/drivers')"
                  >Close</v-btn>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div
                  v-if="loadingNote"
                  class="loader-holder">
                  <rotate-square2 />
                </div>
                <v-container v-else>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Admin`s note</div>
                    <v-textarea
                      v-model="userData.adminNote"
                      label="Admin note"
                      hide-details
                      outline
                    />
                    <v-btn
                      color="pink"
                      dark
                      class="btn-add-note"
                      @click="saveNote()"
                    >Save</v-btn>
                  </div>

                  <v-dialog
                    v-model="noteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog">
                      <v-textarea
                        v-model="editNoteObject.text"
                        outline
                        rows="6"
                        class="custom-textarea mt-3"
                        placeholder="Leave your message here"
                      />
                      <v-btn
                        class="btn-submit white--text"
                        color="pink"
                        block
                        @click="editNote()"
                      >
                        Update note
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </div>
                  </v-dialog>
                  <v-dialog
                    v-model="newNoteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog">
                      <v-textarea
                        v-model="newNoteObject.text"
                        outline
                        rows="6"
                        class="custom-textarea mt-3"
                        placeholder="Leave your message here"
                      />
                      <v-btn
                        class="btn-submit white--text"
                        color="pink"
                        block
                        @click="addNote"
                      >
                        Save
                        <v-icon>arrow_forward</v-icon>
                      </v-btn>
                    </div>
                  </v-dialog>
                  <v-dialog
                    v-model="removeNoteDialog"
                    max-width="500px"
                    transition="dialog-bottom-transition"
                  >
                    <div class="note-dialog py-4">
                      <h2 class="text-center mt-0 mb-4">Weet je zeker dat?</h2>

                      <div class="note-actions">
                        <v-btn
                          color="green"
                          text
                          @click="cancelRemoveNoteDialog"
                        >
                          Annuleren
                        </v-btn>

                        <v-btn
                          color="red"
                          text
                          @click="deleteNote"
                        >
                          Bevestigen
                        </v-btn>
                      </div>
                    </div>
                  </v-dialog>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-layout>
                    <v-flex
                      xs12
                      sm6>
                      <v-select
                        v-if="user && user.brands && user.brands.length"
                        :items="user.brands"
                        v-model="scheduleBrand"
                        :item-text="(val) => { return val.name }"
                        :item-value="(val) => { return val }"
                        label="Brand"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-container>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Default Schedule</div>
                    <v-data-table
                      :headers="headersSchedule"
                      :items="scheduleData"
                      :loading="loading"
                      class="elevation-1"
                      hide-actions
                      hide-default-header
                      hide-default-footer
                    >
                      <template v-slot:items="props">
                        <td>
                          {{ props.item.type }}
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.monday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.tuesday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.wednesday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.thursday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.friday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.saturday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            v-model="props.item.sunday"
                            class="align-center justify-center checkbox-not-margin"
                            hide-details
                            @change="updateScheduleData"
                          />
                        </td>
                      </template>
                    </v-data-table>
                  </div>
                </v-container>
                <v-container>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Availability</div>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        lg4
                        md5
                        sm6>
                        <v-date-picker
                          ref="picker"
                          v-model="date"
                          :first-day-of-week="1"
                          full-width
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        lg8
                        md7
                        sm6>
                        <div class="flex-column">
                          <div class="flex-column">
                            <h3>{{ date }}</h3>
                          </div>
                          <div class="items-center justify-start">
                            <v-checkbox
                              v-model="scheduleCheckboxes.daytime"
                              hide-details
                              label="Daytime"
                              @change="(val) => { scheduleChangeDaytime(val) }" />
                            <v-checkbox
                              v-model="scheduleCheckboxes.evening"
                              hide-details
                              label="Evening"
                              @change="(val) => { scheduleChangeEvening(val) }" />
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Declarations</div>
                    <v-data-table
                      :headers="headersExpenses"
                      :items="expensesList"
                      :loading="loading"
                      class="elevation-1"
                      hide-actions
                      hide-default-header
                      hide-default-footer
                    >
                      <template v-slot:items="props">
                        <td>
                          <b>id:</b>&nbsp;
                          <router-link
                            :to="`/dashboard/ride-overview?rideId=${props.item.ride.id}`"
                            target="_blank"
                            @click.native="rideOverviewClick" >
                            {{ props.item.ride.id }}
                          </router-link>
                          <br>
                          <b>startAt:</b>&nbsp;{{ momentDateTime(props.item.ride.startAt) }}
                        </td>
                        <td>
                          <div
                            v-for="(invoice, index) in props.item.invoices"
                            :key="index">
                            <b>id:</b>&nbsp;
                            <router-link
                              :to="`/tables/invoices/${invoice.id}`"
                              target="_blank" >
                              {{ invoice.id }}
                            </router-link>
                            <br>
                            <b>customer:</b> {{ invoice.passengerName }}
                            <hr>
                          </div>
                        </td>
                        <td>
                          <div
                            v-for="(invoice, index) in props.item.reverseInvoices"
                            :key="index">
                            <b>id:</b>&nbsp;
                            <router-link
                              :to="`/tables/reverse-invoices/${invoice.id}`"
                              target="_blank" >
                              {{ invoice.id }}
                            </router-link>
                            <br>
                            <b>customer:</b> {{ invoice.ride.passenger.name }}
                            <hr>
                          </div>
                        </td>
                      </template>
                    </v-data-table>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Upload file</div>
                    <v-flex
                      xs12
                      sm6>
                      <input
                        ref="driverFiles"
                        type="file"
                        class="custom-file-input"
                        multiple
                        accept="*"
                        @change="selectFiles">
                      <v-btn
                        v-bind="{disabled: !filesData.length}"
                        :loading="isUploadFile"
                        color="primary"
                        class="mr-3"
                        @click="saveFiles()"
                      >Add files</v-btn>
                    </v-flex>
                  </div>
                </v-container>
                <v-container>
                  <div class="field-wrapper">
                    <div class="custom-label black--text mb-3">Files</div>
                    <v-data-table
                      :headers="[{ text: 'Created At', align: 'center', sortable: false }, { text: 'Filename', align: 'center', sortable: false }, { text: '', align: 'center', sortable: false } ]"
                      :items="filesList"
                      class="elevation-1"
                      hide-actions
                      hide-default-header
                      hide-default-footer
                    >
                      <template v-slot:items="props">
                        <td class="text-center">
                          {{ momentDateTime(props.item.file.createdAt) }}
                        </td>
                        <td class="text-center">
                          {{ props.item.file.originalName }}
                        </td>
                        <td class="text-center">
                          <div class="d-flex">
                            <v-btn
                              :loading="deleteFileLoading"
                              color="red"
                              dark
                              @click="deleteFile(props.item)"
                            >
                              Delete
                            </v-btn>

                            <v-btn
                              :href="props.item.file.url"
                              color="primary"
                              dark
                              download
                            >
                              <v-icon>download</v-icon>
                            </v-btn>
                          </div>
                        </td>
                      </template>
                    </v-data-table>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </app-card>
        </v-flex>
      </v-layout>
      <page-saving-status :status="savingStatus" />
    </v-container>
  </div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import Request from '../../../../helpers/request'
import Brands from '../../../../helpers/brands'
import Roles from '../../../../helpers/roles'
import { TEXT_FIELD_MAX_LENGTH, BRAND, SAVING_STATUS } from '../../../../constants/common'
import DriverSchedule from '../../../../helpers/driver-schedule'
import StatusList from '../../../../mixins/StatusList.vue'
import _debounce from 'lodash/debounce'
import AppConfig from 'Constants/AppConfig'
import { createBase64File } from '../../../../helpers/helpers'
import PageSavingStatus from 'Components/PageSavingStatus/PageSavingStatus'

const scheduleData = [
  {
    type: 'daytime',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  },
  {
    type: 'evening',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  }
]

const headersSchedule = [
  { text: '', align: 'start', value: 'type', sortable: false },
  { text: 'Mo', align: 'center', value: 'monday', sortable: false },
  { text: 'Tu', align: 'center', value: 'tuesday', sortable: false },
  { text: 'We', align: 'center', value: 'wednesday', sortable: false },
  { text: 'Th', align: 'center', value: 'thursday', sortable: false },
  { text: 'Fr', align: 'center', value: 'friday', sortable: false },
  { text: 'Sa', align: 'center', value: 'saturday', sortable: false },
  { text: 'Su', align: 'center', value: 'sunday', sortable: false }
]

const headersExpenses = [
  { text: 'Ride', sortable: false },
  { text: 'Invoices', sortable: false },
  { text: 'Reverse invoices', sortable: false }
]

const FILE = {
  name: 'Choose file',
  content: null,
  type: 'expense',
  reference: null,
  url: null
}

export default {
  components: { PageSavingStatus },
  mixins: [Request, Brands, DriverSchedule, StatusList, Roles],
  data () {
    return {
      savingStatus: SAVING_STATUS.default,
      TEXT_FIELD_MAX_LENGTH,
      BRAND,
      brandList: [],
      roleList: [],
      scheduleBrandId: null,
      scheduleBrand: null,
      regionList: [],
      loading: true,
      typesList: [],
      certificatesList: [],
      expensesList: [],
      filesList: [],
      userData: {},
      initUserData: {},
      user: null,
      initUser: {},
      avatarFile: FILE,
      setNewImage: false,
      deleteButtonLoader: false,
      driverNotePage: 1,
      driverNoteTotalPage: 0,
      driverNotePerPage: 20,
      driverNotes: [],
      loadingNote: false,
      noteDialog: false,
      newNoteDialog: false,
      removeNoteDialog: false,
      removeNoteId: null,
      editNoteObject: {},
      newNoteObject: {},
      scheduleData,
      headersSchedule,
      headersExpenses,
      scheduleCheckboxes: {
        daytime: false,
        evening: false
      },
      driverAvailabilityData: [],
      date: moment().format('YYYY-MM-DD'),
      selectedDate: '',
      driverId: null,
      filesData: [],
      deleteFileLoading: false,
      isUploadFile: false,
      showPasswordField: false,
      loginOptions: [],
      driverTypes: {
        driveme: null,
        rentabob: null,
        edrivers: null
      },
      initDriverTypes: {
        driveme: null,
        rentabob: null,
        edrivers: null
      }
    }
  },
  computed: {
    driverTypesAll () {
      let result = []
      if (this.driverTypes.driveme) {
        result.push(this.driverTypes.driveme)
      }
      if (this.driverTypes.rentabob) {
        result.push(this.driverTypes.rentabob)
      }
      if (this.driverTypes.edrivers) {
        result.push(this.driverTypes.edrivers)
      }
      return result
    }
  },
  watch: {
    date: {
      async handler (val, oldValue) {
        if (val && val !== oldValue) {
          this.selectedDate = val

          await this.initDriverAvailability()
        }
      },
      deep: true
    },
    scheduleBrand: {
      async handler (val) {
        this.scheduleBrandId = this.scheduleBrand.id
        await this.getScheduleData(val.id)
        await this.initDriverAvailability()
      },
      deep: true
    },
    user: {
      handler (user) {
        const filteredRoles = ['ROLE_PASSENGER', 'ROLE_DRIVER']

        let loginOptions = []

        user.brands.forEach(brand => {
          user.roles.filter(role => filteredRoles.includes(role)).forEach(role => {
            loginOptions.push({
              text: role === 'ROLE_PASSENGER' ? `Login as customer (${brand.name})` : `Login as driver (${brand.name})`,
              callback: () => {
                window.open(`${AppConfig.BASE_URL[brand.name][AppConfig.env].replace('rentabob', 'driveme').replace('edrivers', 'driveme')}/one-time-login/user/${user.id}/${brand.id}/${role === 'ROLE_PASSENGER' ? 'passenger' : 'driver'}`, '_blank')
              }
            })
          })
        })

        this.loginOptions = loginOptions
      },
      deep: true
    },
    'userData.brands': {
      handler () {
        if (!this.userData.brands || !this.userData.brands.length) {
          this.driverTypes.driveme = null
          this.driverTypes.rentabob = null
          this.driverTypes.edrivers = null
        }
        if (this.brandList.length) {
          if (!this.userData.brands.includes(this.brandList.filter(item => item.name === BRAND.DRIVE_ME).find(item => item.id).id)) {
            this.driverTypes.driveme = null
          }

          if (!this.userData.brands.includes(this.brandList.filter(item => item.name === BRAND.RENTABOB).find(item => item.id).id)) {
            this.driverTypes.rentabob = null
          }

          if (!this.userData.brands.includes(this.brandList.filter(item => item.name === BRAND.EDRIVERS).find(item => item.id).id)) {
            this.driverTypes.edrivers = null
          }
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    this.driverId = this.$route.params.id

    try {
      await this.requestData()
      document.title = this.userData.name || 'Driver'
      this.brandList = await this.fetchBrands()
      this.roleList = await this.fetchRoles()
      const currentBrand = this.user.brands

      if (currentBrand) {
        this.scheduleBrandId = currentBrand[0].id
        this.scheduleBrand = currentBrand[0]
      }
      this.initDriverAvailability(this.driverId)
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    inputSaving () {
      if (!lodash.isEqual(this.user, this.initUser) || !lodash.isEqual(this.userData, this.initUserData) || !lodash.isEqual(this.driverTypes, this.initDriverTypes)) {
        this.savingStatus = SAVING_STATUS.wait
      } else {
        this.savingStatus = SAVING_STATUS.default
      }
    },
    async blurSaving () {
      if (!lodash.isEqual(this.user, this.initUser) || !lodash.isEqual(this.userData, this.initUserData) || !lodash.isEqual(this.driverTypes, this.initDriverTypes)) {
        try {
          await this.saveFunction()
          this.savingStatus = SAVING_STATUS.saved
        } catch (error) {
          this.savingStatus = SAVING_STATUS.error
        }
      }
    },
    rideOverviewClick () {
      this.$store.dispatch('removeAdminRideOverviewFilters')
    },
    typesListBrand (brandName) {
      return this.typesList.filter(item => item.brand.name === brandName)
    },
    scheduleChangeDaytime: _debounce(async function (available) {
      this.scheduleChange(available, this.AVAILABILITY_TYPES.DAYTIME)
    }, 1000),
    scheduleChangeEvening: _debounce(async function (available) {
      this.scheduleChange(available, this.AVAILABILITY_TYPES.EVENING)
    }, 1000),
    updateScheduleData: _debounce(async function () {
      await this.scheduleUpdate(this.scheduleData.find(item => item.type === this.AVAILABILITY_TYPES.EVENING))
      await this.scheduleUpdate(this.scheduleData.find(item => item.type === this.AVAILABILITY_TYPES.DAYTIME))
    }, 1000),
    async getScheduleData (brandId) {
      const { data: { data } } = await this.getDefaultSchedules({ driver: this.driverId, brand: brandId })

      if (data.length) {
        this.scheduleData = data
      } else {
        this.scheduleData = scheduleData
      }
    },
    openEditNoteDialog (note) {
      this.noteDialog = true
      this.editNoteObject = note
    },
    editNote () {
      const data = {
        data: {
          text: this.editNoteObject.text
        }
      }

      this.request('PATCH', `/notes/${this.editNoteObject.id}`, data, () => {
        this.noteDialog = false

        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'The note has been updated'
        })
      })
    },
    openRemoveNoteDialog (id) {
      this.removeNoteDialog = true
      this.removeNoteId = id
    },
    cancelRemoveNoteDialog () {
      if (confirm('Are you sure you want to close?')) {
        this.removeNoteId = null
        this.removeNoteDialog = false
      }
    },
    deleteNote: function () {
      this.request('DELETE', `/notes/${this.removeNoteId}`, {}, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'The note has been deleted'
        })
        this.driverNotes = this.driverNotes.filter(item => item.id !== this.removeNoteId)
        this.getDriverNotes(this.driverId, this.driverNotePerPage)
        this.removeNoteId = null
        this.removeNoteDialog = false
      })
    },
    getDriverNotes (id, limit, page = 1, sortDir = 'desc') {
      this.request('GET', `/notes?driver=${id}&page=${page}&pageSize=${limit}&sort=createdAt&sortDir=${sortDir}`, {}, ({ data }) => {
        this.driverNoteTotalPage = Math.ceil(data.total / this.driverNotePerPage)
        this.driverNotes = data.data
      }, (loader) => { this.loadingNote = loader })
    },
    loadNotes () {
      this.getDriverNotes(this.driverId, this.driverNotePerPage, this.driverNotePage, this.sortDirection)
    },
    saveNote () {
      let body = {
        data: {
          adminNote: this.userData.adminNote
        }
      }

      this.request('PATCH', `/drivers/${this.userData.id}`, body, () => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Admin has left a note to the driver'
        })

        this.loadNotes()
      })
    },
    addNote () {
      const body = {
        data: {
          text: this.newNoteObject.text,
          driver: this.userData.id
        }
      }

      this.request('POST', '/notes', body, () => {
        this.loadNotes()
        this.newNoteDialog = false
        this.newNoteObject = {}
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Admin has left a note to the driver'
        })
      })
    },
    fetchUser (userId) {
      return new Promise((resolve, reject) => {
        this.request('GET', `/users/${userId}`, {}, ({ data }) => {
          resolve(data)
        }, null, reject)
      })
    },
    async requestData () {
      this.loading = true
      const { data } = await this.request('GET', `/drivers/${this.driverId}`)
      this.userData = data

      try {
        const user = await this.fetchUser(data.user.id)

        this.user = user
      } catch (err) {
        throw new Error(err)
      }

      if (data.experience != null) {
        this.userData.yearsExperience = data.experience.years
        this.userData.hoursExperience = data.experience.hours
      }

      if (data.certificates.length) {
        this.userData.certificates = data.certificates.map(item => ({
          ...item.certificate,
          originId: item.id,
          obtainedAt: this.moment(item.obtainedAt)
        }))
      }

      if (data.photo != null) this.userData.imageData = data.photo.url
      if (data.types != null) {
        const drivemeType = data.types.find(item => item.brand.name === BRAND.DRIVE_ME)
        const rentabobType = data.types.find(item => item.brand.name === BRAND.RENTABOB)
        const edriversType = data.types.find(item => item.brand.name === BRAND.EDRIVERS)
        this.driverTypes.driveme = drivemeType ? drivemeType.id : null
        this.driverTypes.rentabob = rentabobType ? rentabobType.id : null
        this.driverTypes.edrivers = edriversType ? edriversType.id : null
      }

      let getTypesList = new Promise((resolve) => {
        this.request('GET', '/driver-types?pageSize=9999', {}, ({ data: driverTypesData }) => {
          this.typesList = driverTypesData.data
          resolve()
        })
      })

      let getCertificatesList = new Promise((resolve) => {
        this.request('GET', '/driver-certificates?pageSize=9999', {}, ({ data: driverCertsData }) => {
          this.certificatesList = driverCertsData.data.map(item => ({ ...item, obtainedAt: '' }))

          resolve()
        })
      })

      let getRegionList = new Promise((resolve) => {
        this.request('GET', '/regions?pageSize=9999', {}, ({ data: regionsData }) => {
          this.regionList = regionsData.data

          resolve()
        })
      })

      let getExpensesList = new Promise((resolve) => {
        this.request('GET', `/drivers/${this.driverId}/rides-for-declaration`, {}, ({ data: expensesData }) => {
          this.expensesList = expensesData
          if (this.expensesList.length) {
            this.expensesList.map(item => {
              item.createdAt = this.moment(item.createdAt)
            })
          }
          resolve()
        })
      })

      let getFilesList = new Promise((resolve) => {
        this.request('GET', `/driver-files`, {}, ({ data: filesData }) => {
          this.filesList = filesData.data
          this.deleteFileLoading = false
          resolve()
        })
      })

      await Promise.all([getTypesList, getCertificatesList, getRegionList, getExpensesList, getFilesList])
      this.initUser = lodash.cloneDeep(this.user)
      this.initUserData = lodash.cloneDeep(this.userData)
      this.initDriverTypes = lodash.cloneDeep(this.driverTypes)
      this.loading = false
    },
    deleteDriverCertificate (id) {
      this.deleteButtonLoader = true
      this.request('DELETE', `/driver-certificates/drivers/${id}`, {}, ({ data }) => {
        this.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver certificate is deleted'
        })
      }, (loader) => { this.deleteButtonLoader = loader })
    },
    onPickFile () {
      this.$refs.fileInputAvatar.click()
    },
    onFilePicked (event) {
      this.avatarFile = FILE
      const files = event.target.files

      if (files[0] !== undefined) {
        createBase64File(files[0]).then((result) => {
          this.avatarFile = { ...this.avatarFile, content: result, name: files[0].name }
        })
      }
    },
    previewFiles (event) {
      this.files = this.$refs.fileInputAvatar.files

      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.userData.imageData = e.target.result
          this.userData.imageName = input.files[0].name
          this.setNewImage = true
          this.$forceUpdate()
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    async saveDriverPhoto (reference, file) {
      return new Promise((resolve) => {
        let body = {
          data: {
            'name': this.userData.imageName,
            'content': file,
            'type': 'driver-photo',
            'reference': reference
          }
        }
        this.request('POST', '/files', body, ({ data }) => {
          this.setNewImage = false
          resolve(1)
        })
      })
    },
    async saveFunction () {
      for (const [key, value] of Object.entries(this.userData.tariffs)) {
        this.userData.tariffs[key] = value && value.replace(/,/g, '.')
      }
      let body = {
        data: {
          'tariffs': this.userData.tariffs,
          'description': this.userData.description,
          'address': this.userData.address,
          'minHoursPerRide': this.userData.minHoursPerRide.time,
          'hoursExperience': this.userData.hoursExperience,
          'yearsExperience': this.userData.yearsExperience,
          'types': this.driverTypesAll,
          'brands': this.userData.brands.map(brand => brand.id || brand),
          'regions': this.userData.regions.map(region => region.id || region),
          'adminNote': this.userData.adminNote,
          'isKOR': this.userData.isKOR,
          'isZZP': this.userData.isZZP,
          'business': this.userData.business,
          'companyName': this.userData.companyName,
          'kvkNumber': this.userData.kvkNumber,
          'ibanNumber': this.userData.ibanNumber,
          'vatNumber': this.userData.isKOR || this.userData.isZZP ? this.userData.vatNumber : undefined,
          'personnelNumber': this.userData.personnelNumber,
          'hasTimeLimitsForFixedPrice': this.userData.hasTimeLimitsForFixedPrice
        }
      }

      await this.request('PATCH', `/drivers/${this.driverId}`, body, async ({ data }) => {
        if (this.userData.imageData != null && this.setNewImage) {
          let imageData = this.userData.imageData.replace(/^data:image.+;base64,/, '')

          this.saveDriverPhoto(data.id, imageData).then(() => {

          })
        }

        if (this.userData.certificates.length) {
          const updateCertificates = this.userData.certificates.filter(item => item.originId)
          const newCertificates = this.userData.certificates.filter(item => !item.originId)

          const certificatesPromisesUpdate = updateCertificates.map((certificate) => {
            const body = {
              data: {
                obtainedAt: certificate.obtainedAt
              }
            }

            return new Promise((resolve) => {
              this.request('PATCH', `/driver-certificates/drivers/${certificate.originId}`, body, () => {
                resolve()
              })
            })
          })

          const certificatesPromisesNew = newCertificates.map((certificate) => {
            const body = {
              data: {
                driver: data.id,
                certificate: certificate.id,
                obtainedAt: certificate.obtainedAt
              }
            }

            return new Promise((resolve) => {
              this.request('POST', `/driver-certificates/drivers`, body, () => {
                resolve()
              })
            })
          })

          await Promise.all([...certificatesPromisesUpdate, ...certificatesPromisesNew])

          // this.$router.push('/tables/drivers')
        }
      })

      let bodyUser = {
        data: {
          'active': this.user.active,
          'email': this.user.email,
          'name': this.user.firstName + ' ' + this.user.lastName,
          'phone': this.user.phone && this.user.phone.number,
          'roles': this.user.roles && this.user.roles.join(','),
          'plainPassword': this.user.plainPassword
        }
      }

      await this.request('PATCH', `/users/${this.user.id}`, bodyUser, () => {
        this.showPasswordField = false
      })

      this.initUser = lodash.cloneDeep(this.user)
      this.initUserData = lodash.cloneDeep(this.userData)
      this.initDriverTypes = lodash.cloneDeep(this.driverTypes)

      // this.$notify({
      //   group: 'topRight',
      //   type: 'success',
      //   text: 'Chauffeur is gewijzigd'
      // })
    },
    saveUser () {
      let body = {
        data: {
          'active': this.user.active,
          'email': this.user.email,
          'name': this.user.firstName + ' ' + this.user.lastName,
          'phone': this.user.phone && this.user.phone.number,
          'country': this.user.country,
          'language': this.user.language,
          'roles': this.user.roles && this.user.roles.join(','),
          'brands': this.user.brands && this.user.brands.map(brand => brand.id || brand),
          'plainPassword': this.user.plainPassword
        }
      }

      this.request('PATCH', `/users/${this.user.id}`, body, () => {
        this.showPasswordField = false
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Gebruiker is gewijzigd'
        })
      })
    },
    sendWelcome () {
      let body = {
        data: {
          driver: this.userData.id
        }
      }

      this.request('POST', '/emailing/driver-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    },
    moment (val) {
      return moment(val).format('YYYY-MM-DD')
    },
    momentDateTime (val) {
      return moment(val).format('YYYY-MM-DD HH:mm')
    },
    async scheduleUpdate (val) {
      const existedDaytime = this.scheduleData.find(scheduleDataItem => scheduleDataItem.id && scheduleDataItem.type === val.type)

      try {
        if (existedDaytime) {
          this.updateDefaultSchedule(existedDaytime.id, val)
        } else {
          const res = await this.createDefaultSchedule({ ...val, driver: this.driverId, brand: this.scheduleBrand.id })
          this.scheduleData = this.scheduleData.map(item => {
            if (item.type === res.data.type) {
              return res.data
            }
            return item
          })
        }
      } catch (error) {
      }
    },
    async initDriverAvailability (driverId) {
      const formattedDate = moment(this.date).format('YYYY-MM-DD')

      const { data: { data: driverAvailabilityData } } = await this.getDriverAvailability({
        driver: driverId || this.driverId,
        brand: this.scheduleBrandId,
        day: formattedDate
      })

      this.driverAvailabilityData = driverAvailabilityData

      if (!driverAvailabilityData.length) {
        this.scheduleCheckboxes.daytime = false
        this.scheduleCheckboxes.evening = false
      } else {
        this.scheduleCheckboxes.daytime = false
        this.scheduleCheckboxes.evening = false
        driverAvailabilityData.forEach(availItem => {
          if (this.scheduleCheckboxes[availItem.type] !== undefined && this.scheduleCheckboxes[availItem.type] !== availItem.available) {
            this.scheduleCheckboxes[availItem.type] = availItem.available
          }
        })
      }
    },
    async scheduleChange (available, scheduleType) {
      const isDaytime = scheduleType === this.AVAILABILITY_TYPES.DAYTIME
      const formattedDate = moment(this.date).format('YYYY-MM-DD')
      const nextFormattedDate = moment(this.date).add('days', 1).format('YYYY-MM-DD')
      const findAvailable = this.driverAvailabilityData.find(availabilityItem => availabilityItem.type === scheduleType)

      if (findAvailable) {
        try {
          await this.deleteDriverAvailability(findAvailable.id)
        } catch (error) {}
      } else if (available && !findAvailable) {
        try {
          await this.createDriverAvailability(
            {
              startAt: isDaytime ? `${formattedDate} 04:00:00` : `${formattedDate} 18:00:00`,
              endAt: isDaytime ? `${formattedDate} 18:00:00` : `${nextFormattedDate} 04:00:00`,
              available,
              type: scheduleType,
              day: formattedDate,
              driver: this.driverId,
              brand: this.scheduleBrandId
            }
          )
        } catch (error) {}
      }

      this.initDriverAvailability()
    },
    selectFiles (event) {
      var input = event.target

      if (input.files && input.files.length > 0) {
        input.files.forEach(file => {
          var reader = new FileReader()
          reader.onload = (e) => {
            this.filesData.push({ data: e.target.result.replace(/^data:image.+;base64,/, ''), name: file.name })
          }

          reader.readAsDataURL(file)
        })
      }
    },
    async saveFiles () {
      if (this.filesData.length) {
        this.isUploadFile = true

        await Promise.all(this.filesData.map(file => {
          let body = {
            data: {
              'name': file.name,
              'content': file.data,
              'type': 'driver-file',
              'reference': this.driverId
            }
          }
          return new Promise((resolve) => {
            this.request('POST', '/files', body, () => {
              resolve()
            })
          })
        }))

        this.requestData()
        this.filesData = []
        this.$refs.driverFiles.value = null
        this.isUploadFile = false
      }
    },
    async deleteFile (item) {
      if (item) {
        try {
          this.deleteFileLoading = true
          await this.request('DELETE', `/driver-files/${item.id}`)
          this.requestData()
        } catch (error) {
          this.deleteFileLoading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.field-wrapper {
  border: 2px solid rgba(0,0,0,0.54);
  border-radius: 4px;
  padding: 12px;
  margin: 0 0 15px 0;

  .custom-label {
    font-size: 1.1rem;
  }
}

.driver-note-wrapper {
  .custom-label {
    font-size: 12px;
  }

  .driver-note-holder {
    border-radius: 5px;
    padding: 10px;
    background-color: #F1F1F1;
    margin: 0 0 10px 0;

    .note-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      font-size: 12px;
    }
  }
}

.note-dialog {
  background-color: white;
  padding: 20px;
}

.note-actions {
  display: flex;

  .v-btn {
    color: white;
    width: 100%;
    flex: 1;
  }
}

.pagination-holder {
  display: flex;
  justify-content: center;
}

.sort-direction {
  width: 160px;
  margin: 0 0 0 auto;
}

  .loader-holder {
    min-height: 300px;
  }

  .btn-add-note {
    margin: 15px 0 0 0;
  }
</style>

<style lang="scss">
  .attach-button .v-btn__content {
    display: block;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
</style>
